import { Component, cloneElement, toChildArray, createRef} from "preact";
import { createPortal } from 'preact/compat';
import { connect } from 'react-redux';

import _ from 'lodash';
import { ADMIN_FRAME } from "../../globals";
import * as helpers from "@cargo/common/helpers";

let editorOverlayAPI = {};
import SwapItem from './drop-swap-media-item';
import InsertContentButton from './insert-content-button';
import { pausePreactRendering, resumePreactRendering } from '../../helpers';


/*currently, each independent editor element controls its own rect and updates its own position
 <bodycopy> uses autoTrack, which means that the controller will update it
 */

class EditorOverlayController extends Component {
	
	constructor(props){
		super(props);

		this.sR = document.createElement('div');

		// testHover might be called by many elements using the overlay
		// since it affects all overlay elements, it should be throttled
		this.testHover = _.throttle(this.testHover, 60);

		this.lockedElements = [];

		this.state = {
			selecting: false,
			unattachedOverlayContainer: null,
			active: false,
			locked: false,
			loaded: false,
			hoveredElements: [],
		}

		this.onContentChange = _.throttle(this.onContentChange, 80, {leading: true, tailing: true});

		// this only tracks stuff on the window level because it only applies to pinned pages currently
		// if we start adding pins inside pins then this will need to be revisited
		window.addEventListener('scroll', this.onScroll);

		this.resizeObserver = new ResizeObserver((entries)=>{
			entries.forEach((entry)=>{
				this.updateElementRect(entry.target)
			});
			
		});		

		this.dragController = null;
		this.portalMap = new Map();
		this.noPointerEventsArray = [];

		this.editorOverlayRef = createRef();

		if( CargoEditor && Object.keys(CargoEditor || {}).length > 0 ){
			this.bindEditorEvents();
		} else {
			window.addEventListener('CargoEditor-load', this.bindEditorEvents);
		}


		this.findDragController().then((dragController)=>{
			this.dragController = dragController;
			this.dragController.on('dragstart', this.onDragStart)
			this.dragController.on('dragend', this.unlock)
			this.dragController.on('drop', this.unlock)

		}).catch((e)=>{
			console.warn('Editor overlay failed to find drag controller.', e)
		})

		editorOverlayAPI.lock = this.lock;
		editorOverlayAPI.unlock = this.unlock;
		editorOverlayAPI.lockItem = this.lockItem;
		editorOverlayAPI.unlockItem = this.unlockItem;
		editorOverlayAPI.setHoveredElements = this.setHoveredElements;
		editorOverlayAPI.deactivateHoverElement = this.deactivateHoverElement;
		editorOverlayAPI.activateHoverElement = this.activateHoverElement;
		editorOverlayAPI.getPortal = this.getPortal;
		editorOverlayAPI.removePortal = this.removePortal;
		editorOverlayAPI.portalMap = this.portalMap;
		editorOverlayAPI.testHover = this.testHover;
		editorOverlayAPI.updateElementRect = this.updateElementRect;

		this.pointerPosition = {
			x: -9e9,
			y: -9e9
		}
	}

	render(){

		var contentButtonLocalStorage = localStorage.getItem('c3-plus-button') === 'true';

		return <div
			className={this.state.selecting? 'selecting': ''}
			id="editor-overlay"
			ref={this.editorOverlayRef} 
			style={{
				display: this.state.loaded ? '': 'none'
			}}
		>

			{this.state.unattachedOverlayContainer && createPortal(
				<><SwapItem/>
				
				{ contentButtonLocalStorage ? (
					<InsertContentButton />
				) : (
				 	null
				)}
				
				</>
			, this.state.unattachedOverlayContainer)}
		</div>
	}

	componentDidMount(){

		if( !this.editorOverlayRef.current.shadowRoot){

		 	this.editorOverlayRef.current.attachShadow({ mode: 'open' });
		 	this.editorOverlayRef.current.shadowRoot.innerHTML = `<link rel="stylesheet" href="${PUBLIC_URL}/css/front-end/editor-ui.css"><div id="unattached-editor-overlays"></div>`;

			// if we have preexisting things waiting in the queue for whatever reason, flush it here
			Array.from(this.sR.children).forEach(child=>{
				this.editorOverlayRef.current.shadowRoot.appendChild(child)
			})		 

		 	const linkEl = this.editorOverlayRef.current.shadowRoot.querySelector('link');
		 	linkEl.addEventListener('load', ()=>{
		 		this.setState({
		 			loaded: true
		 		})
		 	})

		 	const unattachedOverlayContainer = this.editorOverlayRef.current.shadowRoot.querySelector('#unattached-editor-overlays');

		 	this.setState({
		 		unattachedOverlayContainer
		 	});
		}

		this.sR = this.editorOverlayRef.current.shadowRoot;
		window.addEventListener('pointermove', this.onPointerMove);
		window.addEventListener('mousedown', this.onPointerDown);
		window.addEventListener('mouseup', this.onPointerUp);
		window.addEventListener('mouseup', this.onPointerUp);
		document.body.addEventListener('pointerleave', this.onPointerLeave);

		this.findAdminWindow().then(adminWindow=>{
			ADMIN_FRAME.adminWindow.addEventListener('pointermove', this.onAdminPointerMove)
		}).catch((e)=>{
			console.warn('Editor Overlay failed to find Editor.', e)
		})

	}

	componentDidUpdate(prevProps, prevState){
		const {
			hoveredElements: prevHoveredElements
		} = prevState;

		const {
			hoveredElements
		} = this.state;


		const newlyHovered = _.difference( hoveredElements, prevHoveredElements );
		const unHovered = _.difference( prevHoveredElements, hoveredElements );
		unHovered.forEach(el=>{
			const mapItem = this.portalMap.get(el);
			if( mapItem ){
				mapItem.portalHosts.forEach((component, portalHost)=>{
					component?.pointerOut?.();
				});
			}			
		})
		newlyHovered.forEach(el=>{

			const mapItem = this.portalMap.get(el);

			if( mapItem ){
				mapItem.portalHosts.forEach((component, portalHost)=>{
					component?.pointerIn?.();
				});
			}
		})

		this.updatePageButtonPositions();

		if( this.props.adminMode !== prevProps.adminMode ){
			if( this.props.adminMode){
				this.unlock();
				// give overlay items a chance to reinit
				this.afterAdminTimeout = setTimeout(()=>{
					this.testHover();	
				}, 80)
				
			// clear hovered elements, lock overlay
			// so that when we add hover elements back into state they'll have their pointerin methods triggered
			} else {
				this.setHoveredElements([]);
				this.lock();
			}
		}

	}

	componentWillUnmount(){

		clearTimeout(this.afterAdminTimeout);
		this.onContentChange.cancel();

		if( this.dragController){
			this.dragController.off('dragstart', this.onDragStart)
			this.dragController.off('dragend', this.unlock)
			this.dragController.off('drop', this.unlock)			
		}

		if( this.CargoEditor){
			this.CargoEditor.events.off('mutation-start', this.onMutationStart);			
			this.CargoEditor.events.off('mutation-end', this.onMutationEnd);			
			this.CargoEditor.events.off('editor-keyup', this.onContentChange);			
			this.CargoEditor.events.off('editor-summary-created', this.onContentChange);			
		}

		this.portalMap.forEach((value, key, map)=>{
			this.removePortal(key);
		});

		this.noPointerEventsArray = [];

		window.removeEventListener('mousedown', this.onPointerDown);
		window.removeEventListener('mouseup', this.onPointerUp);
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('pointermove', this.onPointerMove);
		document.body.removeEventListener('pointerleave', this.onPointerLeave);

		ADMIN_FRAME.adminWindow?.removeEventListener('pointermove', this.onAdminPointerMove)		

	}

	findAdminWindow = ()=>{

		return new Promise((resolve, reject)=>{

			let findAdminWindowAttempts = 0;
			let checkAdminWindowInterval = null;
			const checkAdminWindow = ()=>{
		
				let adminWindow = ADMIN_FRAME.adminWindow
		
				if( !adminWindow ){
					findAdminWindowAttempts++
				} else {
					clearInterval(checkAdminWindowInterval);					
					resolve(adminWindow);
				}

				if(findAdminWindowAttempts > 200){
					clearInterval(checkAdminWindowInterval);
					reject("not found.");
				}

			};

			checkAdminWindowInterval = setInterval(checkAdminWindow, 300)
			checkAdminWindow();

		});	
	
	}	

	onMutationStart = ()=>{
		pausePreactRendering();
	}

	onMutationEnd = ()=>{
		if( this.ticking){
			return;
		}
		this.ticking = true;
		requestAnimationFrame(()=>{
			resumePreactRendering();
			this.ticking = false;	
		})
		
	}

	bindEditorEvents =()=>{
		window.removeEventListener('CargoEditor-load', this.bindEditorEvents);
		this.CargoEditor = CargoEditor;
		this.CargoEditor.events.on('mutation-start', this.onMutationStart);			
		this.CargoEditor.events.on('mutation-end', this.onMutationEnd);

		this.CargoEditor.events.on('editor-keyup', this.onContentChange);			
		this.CargoEditor.events.on('editor-summary-created', this.onContentChange);
	}	

	findDragController =()=>{

		return new Promise((resolve, reject)=>{

			let findControllerAttempts = 0;
			let dragControllerCheckInterval = null;
			const lookForDragController = ()=>{
		
				let dragController = ADMIN_FRAME.globalDragEventController || null;
		
				if( !dragController ){
					findControllerAttempts++
				} else {
					clearInterval(dragControllerCheckInterval);					
					resolve(dragController);
				}

				if(findControllerAttempts > 200){
					clearInterval(dragControllerCheckInterval);
					reject();
				}

			};

			dragControllerCheckInterval = setInterval(lookForDragController, 300)
			lookForDragController();

		});	
	
	}

	updateElementRect=(el)=>{

		const minHeight = 10;

		const mapItem = this.portalMap.get(el);
		if( mapItem){

			let rect = {
				x: 0,
				y: 0,
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				width: 0,
				height: 0,
			};

			// clamp all rects to pixels for that nice fresh feeling
			const clientRect = el.getBoundingClientRect();
			Object.keys(rect).forEach(key=>{
				rect[key] = Math.round(clientRect[key]);
			});

			if( rect.height < minHeight ){
				rect.height = minHeight;
				rect.bottom = rect.top+minHeight;
			}

			mapItem.extraMargins.forEach((margin, index)=>{
				if(margin === undefined || margin === 0){
					return
				}

				switch(index){
					case 0:
						rect.top = rect.y = rect.top-margin;
						rect.height = rect.height + margin;
						break;

					case 1:
						rect.right = rect.right + margin 
						rect.width = rect.width+margin;
						break;

					case 2:
						rect.height = rect.height+margin;
						rect.bottom = rect.bottom+margin;
						break;

					case 3:
						rect.left = rect.x = rect.left-margin;
						rect.width = rect.width+margin
						break;

				}
			})

			this.portalMap.set(el, {
				portalHosts: mapItem.portalHosts,
				rect: rect,
				extraMargins: mapItem.extraMargins,
			});

			mapItem.portalHosts.forEach((component, portalHost)=>{
				component?.updatePosition?.(rect);
			});

		}
	}

	onContentChange = (editor,summary)=>{
		this.state.hoveredElements.forEach(el=>{
			this.updateElementRect(el);
		})
	}

	onScroll = ()=>{
		this.noPointerEventsArray.forEach(this.updateElementRect);
		this.updatePageButtonPositions();
	}


	updatePageButtonPositions = ()=>{

		// distance between buttons when shifted
		// vertical will always be overlarge since we account for the flipup/down positions when calculating
		const buttonMargin = 1;


		const pageEditButtonMap = Array.from(this.portalMap).map(([el, mapItem])=>{

			let usablePortalHost = null;
			const editButtonComponent = Array.from(mapItem?.portalHosts ?? []).find(([portalHost, component])=> {
				if(component.props?.name == 'EditPageButton' && !component.props.isEditingPage ){
					usablePortalHost = portalHost;
					return true
				}
				return false
			})

			if( !usablePortalHost?.querySelector('button.edit-page') ){
				return false;
			}

			const rect = usablePortalHost.querySelector('button.edit-page').getBoundingClientRect();
			const isAbovePage = rect.top < mapItem.rect.top;

			const adjustedRect = isAbovePage ? {
				left: rect.left + -editButtonComponent[1].state.shiftButtonX,
				width: rect.width + buttonMargin,
				top: rect.top,
				height: rect.height * 2 + buttonMargin,
			} : {
				left: rect.left + -editButtonComponent[1].state.shiftButtonX,
				width: rect.width + buttonMargin,
				top: rect.top - rect.height + -2,
				height: rect.height * 2 +buttonMargin,				
			}
			return {
				mapRect: mapItem.rect,
				portalHost: usablePortalHost,
				proposedRect: adjustedRect,
				rect: adjustedRect,
				component: editButtonComponent[1]
			}

		}).filter(obj=>!!obj);

		const sortedLeftToRight = _.sortBy(pageEditButtonMap, (obj)=>{
			return obj.mapRect.left
		});

		let hasOverlappingButtons = true;
		const maxIterations = 4;
		let iteration = 0;

		while(hasOverlappingButtons && iteration < maxIterations){

			hasOverlappingButtons = false;

			// go through each rect and push its left edge against the right edge of the rightmost overlapping rect
			// iterate on this process until all labels have found a non-overlapping location
			sortedLeftToRight.forEach((obj, index, thisArray)=>{

				// leftmost one stays in place
				if (index ===0){
					return
				}

				const rect = obj.rect;

				const overlappingObjects = thisArray.filter((thisObj)=>{

					if( thisObj == obj ){
						return false;
					}

					const compareRect = thisObj.proposedRect;
					
					return (
						rect.left < (compareRect.left + compareRect.width) && (rect.left + rect.width) > compareRect.left &&
						rect.top < (compareRect.top + compareRect.height) && (rect.top + rect.height) > compareRect.top 
					)
				});

			    // console.log(obj.portalHost.querySelector('button.edit-page').innerText, 'overlaps...', overlappingObjects.map((obj)=>obj.portalHost.querySelector('button.edit-page').innerText).join(',') );

				if( overlappingObjects.length > 0){

					hasOverlappingButtons = true;

					const prevObj = thisArray[index-1];
					const rightMostObj = _.sortBy(overlappingObjects, (obj)=> -(obj.proposedRect.left+obj.proposedRect.width))[0];

			 		obj.proposedRect = {
			 			left: rightMostObj.proposedRect.left+rightMostObj.proposedRect.width,
			 			width: rect.width,
			 			top: rect.top,
			 			height: rect.height,
			 		}

			 		obj.component.setState({
			 			shifted: true,
			 			shiftButtonX: obj.proposedRect.left - rect.left
			 		});

			 		if( index == 1){
			 			sortedLeftToRight[0].component.setState({
			 				shifted: true,
			 				shiftButtonX: 0,
			 			})
			 		}
				} else {
			 		obj.component.setState({
			 			shifted: false,
			 			shiftButtonX: 0
			 		});
			 		if( index == 1){
			 			sortedLeftToRight[0].component.setState({
			 				shifted: false,
			 				shiftButtonX: 0,
			 			})
			 		}		 		
			 	}

			});

			iteration++;
		}

	}

	onDragStart =()=>{
		this.setState({
			hoveredElements: [],
			locked: true,
		})


		this.dragContentChangeTimeout = setTimeout(()=>{
			this.onContentChange()
		}, 30);
	}

	// map an element to its shadow portal destination
	getPortal = (el, options={})=>{
		if(!el) {
			return;
		}

		if( this.portalMap.has(el) ){
			var mapItem = this.portalMap.get(el);

			var portalHostFound = null;
			mapItem.portalHosts.forEach((component, portalHost)=>{
				if( options.component === component){
					portalHostFound = portalHost;
				}
			});

			if( portalHostFound ){
				return portalHostFound
			} else {

				const portalHost = document.createElement('div');
				this.sR.appendChild(portalHost);
				mapItem.portalHosts.set(portalHost, options.component)
				return portalHost;
			}


		
		} else {
			const portalHost = document.createElement('div');

			this.sR.appendChild(portalHost);

			const mapItem = {
				portalHosts: new Map(),
				rect: {width: 0, height: 0, top: 0, left: 0, bottom: 0, right: 0},
				extraMargins: options.extraMargins || [],
			}

			portalHost.setAttribute('editor-overlay-tag-name', el.tagName);
			portalHost._editorBaseNode = el;
			mapItem.portalHosts.set(portalHost , options.component);

			this.portalMap.set(el,mapItem);

			if( options.trackResize ){
				this.resizeObserver.observe(el);
			}
			if( options.noPointerEvents ){
				if ( this.noPointerEventsArray.indexOf(el) === -1){
					this.noPointerEventsArray.push(el);					
				}
			}

			// if we have custom margins around an element, manually compare it to the pointer position
			if( options.extraMargins ){

				if ( this.noPointerEventsArray.indexOf(el) === -1){
					this.noPointerEventsArray.push(el);					
				}

			}	

			return portalHost;
		}

	}

	removePortal = (baseNode, portalHost)=>{

		// if the portalmap has the basenode
		if( this.portalMap.has(baseNode) ){
		
			const mapItem = this.portalMap.get(baseNode);
			
			if( mapItem.portalHosts.has(portalHost) ){
				mapItem.portalHosts.delete(portalHost);
				portalHost.parentNode.removeChild(portalHost);				
			}

			if( mapItem.portalHosts.size === 0){

				this.resizeObserver.unobserve(baseNode);

				if( this.noPointerEventsArray.indexOf(baseNode) > -1 ){
					this.noPointerEventsArray.splice(this.noPointerEventsArray.indexOf(baseNode), 1);
				}

				this.portalMap.delete(baseNode);

			}
			
		}


	}

	// lock state from getting hover-tested
	// individual elements can be updated manually, however
	lock = ()=>{
		this.setState({
			locked: true,
		})
	}

	unlock = ()=>{
		this.setState({
			locked: false
		})
	}

	lockItem = (element)=>{
		if( this.lockedElements.indexOf(element) === -1){
			this.lockedElements.push(element)
		}
	}

	unlockItem = (element)=>{
		const index = this.lockedElements.indexOf(element);
		if( index > -1){
			this.lockedElements.splice(index, 1);
		}		
	}	

	deactivateHoverElement = (element)=>{
		if( !element ){
			return;
		}

		this.setState(prevState=>{
			const {
				hoveredElements
			} = prevState;

			let newHoveredElements =[...hoveredElements];

			const index = hoveredElements.indexOf(element);
			if ( index > -1 ){
				newHoveredElements = newHoveredElements.splice(index, 1);
			} else {
				return null;
			}
			return {
				...prevState,
				hoveredElements: newHoveredElements
			}

		})
	}

	activateHoverElement = (element) => {

		if( !element ){
			return;
		}

		this.setState(prevState=>{
			
			const {
				hoveredElements
			} = prevState;

			let newHoveredElements = [...hoveredElements];


			const indexOf = newHoveredElements.indexOf(element);
			if ( indexOf == -1 ){
				newHoveredElements.push(element);
			} else {
				return null;
			}

			return {
				...prevState,
				hoveredElements: newHoveredElements
			}

		})

	}

	setHoveredElements =( elementOrElementArray )=>{

		let elementArray = Array.isArray(elementOrElementArray) ? elementOrElementArray : [elementOrElementArray];
		elementArray = elementArray.filter(el=>!!el);

		if(!_.isEqual(this.state.hoveredElements, elementArray)) {
			this.setState({
				hoveredElements: elementArray
			});
		}

	}

	testHover = ()=>{

		const {
			locked
		} = this.state;

		if (locked){
			return;
		}

		let hoveredElements = Array.from(document.elementsFromPoint(this.pointerPosition.x, this.pointerPosition.y)) || [];


		// if we're hovered over some part of the overlay, use that part to find the corresponding basenode and indicate that it is hovered over
		let overlayHoveredElements = Array.from(this.editorOverlayRef.current.shadowRoot.elementsFromPoint(this.pointerPosition.x, this.pointerPosition.y)) || [];
		overlayHoveredElements = overlayHoveredElements.map(el=>el.closest('[editor-overlay-tag-name]')?._editorBaseNode).filter(el=>!!el);
		overlayHoveredElements = _.difference(overlayHoveredElements, hoveredElements);

		overlayHoveredElements.forEach(el=>{
			if( el && hoveredElements.indexOf(el) == -1){
				hoveredElements.push(el);
			}
		});





		// elementsFromPoint doesn't capture elements that have pointer-events:none on them, so we loop through their cached rects here
		this.noPointerEventsArray.forEach((el)=>{
			const mapItem = this.portalMap.get(el);
			if( mapItem &&
				mapItem.rect &&
				this.pointerPosition.x <= mapItem.rect.left+mapItem.rect.width &&
				this.pointerPosition.x >= mapItem.rect.left &&
				this.pointerPosition.y <= mapItem.rect.top + mapItem.rect.height &&
				this.pointerPosition.y >= mapItem.rect.top &&
				hoveredElements.indexOf(el) == -1
			){
				hoveredElements.push(el);
			}
		});
		hoveredElements = _.uniq(hoveredElements);

		const prevHoveredElements = [...this.state.hoveredElements];
		this.lockedElements.forEach(el=>{

			let prevHoverIndex = prevHoveredElements.indexOf(el);
			let currentHoverIndex = hoveredElements.indexOf(el);
			// if an element is locked and it was previously hovered, keep it hovered			
			if( currentHoverIndex === -1 && prevHoverIndex > -1){
				hoveredElements.push(el)

			// if an element is locked and it wasn't previously hovered, keep it unhovered				
			} else if ( prevHoverIndex === -1 && currentHoverIndex > -1){
				hoveredElements.splice(currentHoverIndex, 1);
			}
		});

		const activeEditorElement = CargoEditor?.getActiveEditor?.()?.getElement();
		const activeOverlayContainer = activeEditorElement ? activeEditorElement.closest('.overlay-content') : null;
		const hoveredOverOverlayBodycopy = activeOverlayContainer && hoveredElements.some(el=>el.tagName ==='BODYCOPY' && activeOverlayContainer.contains(el));
		if( activeEditorElement && activeOverlayContainer && hoveredOverOverlayBodycopy ){
			hoveredElements = hoveredElements.filter(el=>activeOverlayContainer.contains(el));
		}

		this.setHoveredElements(hoveredElements);		
	}

	onAdminPointerMove = (e)=>{
		// for strange safari behavior where iframe loses focus
		if( e.target.tagName==='IFRAME'){
			this.pointerPosition.x = e.clientX;
			this.pointerPosition.y = e.clientY+-40;
		} else {
			this.pointerPosition.x = -9e9;
			this.pointerPosition.y = -9e9;
		}
		this.testHover();

	}

	onPointerLeave = (e)=>{
		this.setState({
			selecting: false,
		})
		setTimeout(()=>{
			this.setHoveredElements([]);
		},100);
	}

	onFirstSelectionChange = ()=>{
		this.setState({selecting: true})
	}

	onPointerDown = (e)=>{
		this.selectionWaitTimeout = setTimeout(()=>{
			document.addEventListener('selectionchange', this.onFirstSelectionChange, {once: true})
		}, 60)
	}

	onPointerUp = (e)=>{
		clearTimeout(this.selectionWaitTimeout);
		document.removeEventListener('selectionchange', this.onFirstSelectionChange, {once: true})
		this.setState({selecting: false})
	}

	onPointerMove = (e)=>{
		this.pointerPosition.x = e.clientX;
		this.pointerPosition.y = e.clientY;
		this.testHover();
	}

}




export { editorOverlayAPI }
export default connect(
    (state, ownProps) => {
        return {
            adminMode       : state.frontendState.adminMode,
        };
    }
)(EditorOverlayController);


