import { toChildArray, h } from "preact";

// turn an element into a preact-ready vdom
export const toVdom = function(element, nodeName, props={}) {

	if (element.nodeType === 3) return element.data;
	if (element.nodeType !== 1) return null;
	let attributeProps ={}
	let children = [],
		i = 0,
		a = element.attributes,
		cn = element.childNodes;
	for (i = a.length; i--; ) {
		attributeProps[a[i].name] = a[i].value;
	}
	props = {
		...attributeProps,
		...props
	}

	for (i = cn.length; i--; ) {
		const vnode = toVdom(cn[i], null);
		children[i] = vnode;
	}

	return h(nodeName || element.nodeName , props, children);
}


// update the attributes of non-preact elements inside the custom element while preventing the insides from being touched
export const diffAttributes = (el, attributes) =>{

	Object.keys(attributes).forEach((key)=>{

		let newValue = attributes[key];			
		const type = typeof newValue;
		if (
			newValue == null ||
			type === 'string' ||
			type === 'boolean' ||
			type === 'number'
		) {
			if( key == 'className'){
				key = 'class'
			}
			const oldValue = el.getAttribute(key);
			if( oldValue != newValue+'' ){

				if(newValue == null){
					el.removeAttribute(key);
				} else {
					el.setAttribute(key, newValue );							
				}

			}
			
		} else{
			if( el[key] != newValue ){
				el[key] = newValue;
			}
		}



	})
}

export const vNodeToString =(vNode, str='')=>{

	if(!vNode){
		return '';
	}

	if (typeof vNode == 'string'){
		str+= vNode
	} else if (vNode.props?.children) {

		vNode.props.children.forEach((vNode)=>{
			str+= vNodeToString(vNode);
		})

		if( vNode.type == 'div'){
			str = ' '+str+' '
		}		

	} else if ( vNode.type =='br'){
		str+=' '
	}

	return str
}


export const generateColumnMap = (columns, gutterWidth, elWidth)=>{
	const divideByTwo = window.devicePixelRatio > 1;


	let columnWidth = (elWidth/columns) - gutterWidth*((columns-1)/columns);

	// make sure each column is at least one pixel wide
	columnWidth = Math.max(columnWidth, 1)

	let columnAndGutterMap = [];


	// add .001 to avoid float errors
	const isOverflowing = columnWidth * columns + gutterWidth * (columns-1) > elWidth+.001;
	if( isOverflowing){
		gutterWidth = Math.floor((elWidth/(columns-1)) - columnWidth);
	}
	let gutterPixelWidth = divideByTwo ? Math.round(gutterWidth * 2)/2 : Math.round(gutterWidth);

	for(let i = 0; i < columns; i++){

		const edge = {
			left: columnWidth * i + gutterWidth * i,
			right: columnWidth * i + gutterWidth * i + columnWidth
		}

		if( i < columns -1 ){
			edge.right = divideByTwo ? Math.round(edge.right*2)/2 : Math.round(edge.right)
		} else {
			edge.right = elWidth;
		}

		if( i > 0){
			edge.left = columnAndGutterMap[i-1].right+gutterPixelWidth	
		}
		
		columnAndGutterMap.push(edge);
	}

	return {
		columnAndGutterMap,
		columnWidth,
		gutterPixelWidth,
		halfPixels: divideByTwo
	};
}
