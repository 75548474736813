import { Component} from "preact";
import _ from 'lodash';
import { ADMIN_FRAME } from "../../globals";

import GalleryEditor from "./gallery-editor"

class GridEditor extends Component {
	constructor(props){
		super(props);

	}

	render(props, state){

		const {
			galleryInstance,
			'vertical-align':verticalAlign
		} = props;
		return <GalleryEditor
				{...props}
				disabledFigureOptions={['scale']}
				gallerySpecificAttributes={this.props.gallerySpecificAttributes}
				mediaItemEditorInfo={{
					disableResize: this.props.disableResize,
					disableButton: false,
					afterScaleChange: this.afterScaleChange,
					beforeScaleChange: this.spanResize,
					whileScaleChange: this.spanResize,
				}}
				galleryInstance={galleryInstance}
				onCleanUp={(addedMediaItems,removedMediaItems,changedMediaItems, characterDataChanged, attributeMap, attributeMapString)=>{

					changedMediaItems.forEach(mediaItem=>{
						if( mediaItem.getAttribute('grid-vertical-align') === verticalAlign){
							mediaItem.removeAttribute('grid-vertical-align')
						}
						if( mediaItem.getAttribute('grid-span') === 1){
							mediaItem.removeAttribute('grid-span');
						}
					})

					removedMediaItems.forEach(mediaItem=>{
						if( mediaItem ){
							mediaItem.classList.remove('last-column');
							mediaItem.classList.remove('last-row');
							mediaItem.classList.remove('orphaned-item');
						}

					})

					if(this.props.onCleanUp){
						this.props.onCleanUp(addedMediaItems,removedMediaItems,changedMediaItems, characterDataChanged, attributeMap, attributeMapString)
					}

				}}				
			/>
	}

	spanResize = (mediaItem, resizeDimensions)=>{
		const spans = this.convertWidthToSpans(resizeDimensions)

		return {
			...resizeDimensions,
			displayScale: spans > 1 ? spans + ' columns' : spans + ' column'
		}
	}

	afterScaleChange = (mediaItem, resizeDimensions)=>{
		const spans = this.convertWidthToSpans(resizeDimensions)
		if( spans === 1){
			mediaItem.removeAttribute('grid-span');
		} else {
			mediaItem.setAttribute('grid-span', spans)			
		}

		this.props.galleryComponent.onItemResize();
	
	}

	convertWidthToSpans = (resizeDimensions)=>{
		const {columnAndGutterMap}= this.props;

		let spans = columnAndGutterMap.findIndex((columnEdge, index)=>{
			const nextLeftEdge = columnAndGutterMap[index+1]?.left || 9e9;
			const colWidth = columnEdge.right - columnEdge.left;
			return resizeDimensions.width >= columnEdge.left + colWidth*.5 && resizeDimensions.width <= nextLeftEdge+ colWidth*.5;
		})+1

		// minimum of one span
		return Math.max(1, spans);		
	}


}

export default GridEditor;