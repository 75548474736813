import { Component } from 'preact'
import Page from "./page";

export default class Set extends Component {

	constructor(props) {
		super(props);
	}

	render = () => {
		const children = this.props.children.filter(child => child.parent === this.props.id);
		const stackedPage = children.filter(child => child.content.page_type=='page').length > 1

		return  <div data-container="set" data-id={this.props.id}>{
			children.map((child, index) => {
				if(child.content.page_type === "set") {
					return <Set key={child.content.id} id={child.content.id} children={this.props.children} hashPurl={this.props.hashPurl} contentPad={this.props.contentPad} />
				} else {
					return <Page key={child.content.id} id={child.content.id} position={child.position} hashPurl={this.props.hashPurl} contentPad={this.props.contentPad} stackedPage={stackedPage} />
				}
			})
		}</div>
	}

}
