export default (slug, options = {}) => {

	// https://github.com/egoist/slugo/blob/master/src/index.js
	const sanitizedSlug = slug
		// Remove special characters
		.replace(/[#$%<>&=\?\\\/]/g, '')
		// Replace dots and spaces with a short dash
		.replace(/(\s|\.)/g, '-')
		// Replace multiple dashes with a single dash
		.replace(/-{2,}/g, '-')
		// Make the whole thing lowercase
		.toLowerCase();

	const safeSlug = (sanitizedSlug.length > 0) ? sanitizedSlug : '_';

	// make sure the slug is unique
	if(options.inUse) {

		let uniqueSlug = safeSlug;
		let counter = 1;

		while(options.inUse.includes(uniqueSlug)) {
			uniqueSlug = safeSlug + `-${counter++}`;
		}

		return uniqueSlug;

	}

	return safeSlug;

}