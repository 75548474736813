import { loadCSS } from '../util';

const API = CARGO_ENV === 'production' ? 'https://api.cargo.site/v1/fonts/css?family=' : 'https://api.dev.cargo.site/v1/fonts/css?family=';

export default (globalConfig, families) => {

	return new Promise((resolve, reject) => {

		if(!families || families.length === 0) {
			// nothing to load
			return resolve();
		}
		
		// load the sheet
		const familyUrl = families
			// insert separator
			.join('%7C')
			// make string web safe
			.replace(/[\. -]+/g, '');


		if(globalConfig.context === "server") {

			fetch(API + familyUrl)
				.then(async result => {
					const css = await result.text();
					resolve(css);
				})
				.catch(reject)

			return;
		}

		const preloadSpans = families.map(family => {
			const span = globalConfig.context.document.createElement('span');
			span.style = `display:block;position:absolute;top:-9999px;left:-9999px;
			font-size:300px;width:auto;height:auto;line-height:normal;margin:0;
			padding:0;font-variant:normal;white-space:nowrap;font-family:${family};`
			span.textContent = 'BESbswy';
			return span;
		});

		// insert spans using the font so the browser will preload the woff files
		preloadSpans.forEach(span => globalConfig.context.document.body.appendChild(span));

		loadCSS(API + familyUrl, globalConfig.context)
			.then(e => {
				globalConfig.context.document.fonts.ready.then(() => {
					preloadSpans.forEach(span => span.remove());
					resolve();
				});
			})
			.catch(reject)

	})

}