body.not-authorized {
  --background-site: #EFEEEC;
  background-color: var(--background-site);
}
body.not-authorized.dark {
  --background-site: #2d2d2d;
}

#login {
  --login-window-width: var(--ui-width-uiWindow);
  --login-input-height: 20px;
}
#login.login-window {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontFamily-default);
  /* transition values */
  --x1: 0.260;
  --y1: 0.270;
  --x2: 0.380;
  --y2: 0.925;
  --enter: cubic-bezier(var(--x1), var(--y1), var(--x2), var(--y2));
  /* Reversed transition values */
  --x1-r: calc(1 - var(--x2));
  --y1-r: calc(1 - var(--y2));
  --x2-r: calc(1 - var(--x1));
  --y2-r: calc(1 - var(--y1));
  --exit: cubic-bezier(var(--x1-r), var(--y1-r), var(--x2-r), var(--y2-r));
  opacity: 0;
  transition: opacity 0.28s var(--enter);
}
#login.login-window.active {
  opacity: 1;
  transition: opacity 0.28s var(--enter);
}
#login.login-window.active .login-modal {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.28s var(--enter), transform 0.28s var(--enter);
}
#login.login-window.active-immediate {
  opacity: 1;
  transition: none;
}
#login.login-window.active-immediate .login-modal {
  transform: translateY(0);
  opacity: 1;
  transition: none;
}
#login form {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--login-input-height) var(--ui-padding-uiWindow) var(--ui-padding-uiWindow) var(--ui-padding-uiWindow);
}
#login form button {
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background-color: transparent;
}
#login form button:active {
  opacity: var(--opacity-downstate-default);
}
#login form button.forgot {
  display: inline-block;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.5);
  margin: 0 0 var(--ui-padding-default-vertical) calc(var(--ui-padding-default-horizontal) + 2px);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  padding: 0;
  border: none;
}
#login form button.login {
  position: relative;
  background: rgba(var(--baseColor-accent-rgb), 1);
  color: var(--ui-color-accent-reverse);
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  font-weight: 500;
  justify-content: flex-start;
  line-height: 1;
  /* border-radius: var(--account-manager-rounded-button-radius); */
  flex: 0 1 125px;
  text-align: left;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px;
  border-radius: var(--ui-button-border-radius);
  -webkit-appearance: none;
  border: none;
  white-space: nowrap;
  display: flex;
  min-height: 28.5px;
}
#login .login-header {
  padding: var(--ui-padding-uiWindow) 0;
  height: 44px;
  border-bottom: 1px solid var(--legacyColor-grayscale-darker);
}
#login .login-header .logo-box {
  width: 40px;
  height: 44px;
  position: absolute;
  left: 0;
  top: 0;
}
#login .login-header .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#login .login-header .title {
  text-align: center;
  font-family: var(--fontFamily-default);
  font-size: 17px;
  font-weight: 400;
  line-height: 0.8;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
#login .enter-email {
  width: 85%;
  line-height: 1.3;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  position: relative;
  bottom: 8.5px;
  flex: calc(var(--height-row) + 1px) 0;
  text-align: left;
  padding-top: 1px;
  margin-bottom: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
#login .login-options {
  flex: 1 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
}
#login .login-modal {
  background-color: var(--ui-background-container);
  width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: var(--window-boxShadow-default);
  transform-origin: bottom center;
  transform: translateY(-15px);
  transition: opacity 0.28s var(--enter), transform 0.28s var(--enter);
  border-radius: var(--windowRadiusLarge);
  font-size: var(--fontSize-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  line-height: var(--lineHeight-default);
  position: fixed;
  border-radius: var(--windowRadiusLarge-inner);
  letter-spacing: var(--account-manager-letter-spacing);
}
#login .login-modal .error {
  text-align: left;
  width: 100%;
  padding-top: 11px;
  margin-top: -1px;
}
#login .login-modal .error .emoji {
  font-family: -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
#login .login-modal:after {
  content: "";
  box-shadow: inset 0px 0px 0px 1px rgba(var(--baseColor-default-reverse-rgb), 0.06);
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  pointer-events: none;
  border-radius: var(--windowRadiusLarge);
}
#login .input-fields {
  display: inline-grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-gap: var(--element-gap);
  align-content: flex-start;
  padding-bottom: 30px;
  width: 100%;
}
#login .text-input {
  width: 100%;
  height: var(--height-row);
  margin-left: auto;
  background: transparent;
  border-radius: var(--height-row);
}
#login .input {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin-top: -6px;
}
#login input {
  border: none;
  border-radius: var(--height-row);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  line-height: calc(var(--ui-lineHeight-default) - 0.1);
  color: var(--ui-color-default);
  background: var(--ui-background-default);
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: calc(var(--ui-padding-default-vertical) - 0px);
  padding-bottom: calc(var(--ui-padding-default-vertical) + 1px);
  padding-left: calc(var(--ui-padding-default-horizontal) + 2px);
  padding-right: var(--ui-padding-default-horizontal);
}
#login input::-webkit-input-placeholder {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  -webkit-text-fill-color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}
#login input:-webkit-autofill, #login input:-webkit-autofill:hover, #login input:-webkit-autofill:focus, #login input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--ui-background-default) inset !important;
  -webkit-text-fill-color: var(--ui-color-flat-reverse) !important;
  background: var(--ui-background-default) !important;
  background-clip: content-box !important;
}

.dark #login {
  --window-boxShadow-default: 2px 2px 19px #141414;
}
.dark #login .login-modal::after {
  box-shadow: none;
  border: 1px solid var(--ui-window-border-background);
  inset: 0;
}

.mobile #login .login-modal {
  max-width: var(--ui-maxWidth-mobile);
}